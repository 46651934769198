import * as React from "react";
import Layout from "../components/layout";
import MapContact from "../components/contact/mapContact";
import BannerImage from "../components/master/BannerImage";
import heroImage from "../assets/contact/contact-hero-image.jpg";

const ContactPage = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="..." />
      <MapContact />
    </Layout>
  );
};

export default ContactPage;
