import React from "react";
import "./mapContact.css";
import mapImage from "../../assets/contact/map-02.jpg";
import {Link} from "gatsby";
import ContentWrapper from "../master/ContentWrapper";
import PageTitle from "../master/PageTittle";

const MapContact = () => (
    <div className="container">
        <ContentWrapper sidebar={false} narrow={false}>
            <div className={"content"}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="contact-info">
                            <PageTitle title="Contact Us"/>
                            <ul>
                                <li>
                  <span>
                    <i className="fas fa-phone-alt"></i>
                  </span>{" "}
                                    <span>
                    <a href="tel:+61(02)95592434">+61 (02) 9559 2434</a>
                  </span>
                                </li>
                                <li>
                  <span>
                    <i className="fas fa-envelope"></i>
                  </span>{" "}
                                    <span>
                    <a href="mail:+info@stmarouns.nsw.edu.au">
                      info@stmarouns.nsw.edu.au
                    </a>
                  </span>

                                </li>


                                <br/>

                                <span>
                    <p>ABN: 34 635 357 087</p>
                </span>{" "}
                                <span>
                    <p>PROVIDER CODE: 03814D</p>
                  </span>{" "}
                            </ul>
                            <br/>

                            <PageTitle title="Enrolments"/>
                            <ul>
                                <li>
                  <span>
                    <i className="fas fa-envelope"></i>
                  </span>{" "}
                                    <span>
                    <a href="mail:+Enrolments@stmarouns.nsw.edu.au">
                      Enrolments@stmarouns.nsw.edu.au
                    </a>
                  </span>

                                </li>

                                <li>
                                    <br/>

                                    <span>
                    <p>St. Maroun's College</p>
                    <p>194 Wardell Road</p>
                    <p>Marrickville, Nsw 2204</p>
                </span>{" "}

                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="map-wrapper">
                            <img src={mapImage} alt="map location"/>
                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    </div>
);

export default MapContact;
